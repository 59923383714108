import React from 'react'

import { Tabs as antTabs } from "antd";
import styled from 'styled-components'
import ActiveContributionAmount from "./icons/ContributionAmountActive.png"
import ActiveSolveDuration from "./icons/SolveDurationActive.png"
import ContributionAmount from "./icons/ContributionAmount.png"
import ActiveGoalAmount from "./icons/GoalAmountActive.png"
import SolveDuration from "./icons/SolveDuration.png"
import GoalAmount from "./icons/GoalAmount.png"
import ActiveSolveInitial from "./icons/SolveInitinalActive.png"
import SolveInitial from "./icons/SolveInitial.png"
import { useSelector, useDispatch } from "react-redux"
import { setMenuState } from 'src/store/Goal-Based/MenuSlice';
import { RootStateType } from 'src/store/rootReducer';
import { theme } from 'src/theme/theme';


const Tabs = styled(antTabs)`
  .ant-tabs-ink-bar {
    background: transparent;
  }
  .ant-tabs-tab {
    font-family: 'Proxima Nova', sans-serif;
    color: '#0000';
    font-weight: 400;
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
    color: ${theme.colors.primary[500]};
    font-weight: 400;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.primary[500]};
    font-weight: 700;
  }
`;
const { TabPane } = Tabs;

const TopHeader = () => {

  const menuState = useSelector((state: RootStateType) => state.menuReducer);
  const dispatch = useDispatch();

  const handleMenuStateChange = (newState: string) => {
    dispatch(setMenuState({ currentState: newState }));
  };

  const onChange = (e) => {
    handleMenuStateChange(e);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "72px",
        backgroundColor: theme.colors.primary[10],
        borderRadius: "10px",
        paddingLeft: "17px",
        marginBottom: "20px"
      }}
    >
      <Tabs defaultActiveKey={menuState.currentState} tabBarStyle={{ paddingLeft: 30 }} tabBarGutter={45} onChange={onChange}>
        <TabPane
          key="GoalAmount"
          name="GoalAmount"
          tab={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px"
              }}
            >
              <img
                src={
                  menuState.currentState === "GoalAmount" ? ActiveGoalAmount : GoalAmount
                }
                width="48px"
                height="48px"
                alt="Private Equity"
              />
              Goal Amount
            </span>
          }
        />
        <TabPane
          key="ContributionAmount"
          tab={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "9px",
                alignItems: "center",
              }}
            >
              <img
                src={menuState.currentState === "ContributionAmount" ? ActiveContributionAmount : ContributionAmount}
                height="30px"
                width="40px"
                alt="ContributionAmount"
              />
              Contribution Amount
            </span>
          }
        />
        <TabPane
          key="SolveInitial"
          tab={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px"
              }}
            >
              <img
                src={menuState.currentState === "SolveInitial" ? ActiveSolveInitial : SolveInitial}
                width="48px"
                height="39px"
                alt="SolveInitial"
              />
              Solve For Initial Amount
            </span>
          }
        />
        <TabPane
          key="SolveDuration"
          tab={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "11px"
              }}
            >
              <img
                src={menuState.currentState === "SolveDuration" ? ActiveSolveDuration : SolveDuration}
                height="48px"
                width="60px"
                alt="SolveDuration"
              />
              Solve For Duration
            </span>
          }
        />
      </Tabs>
    </div>
  );
}

export default TopHeader