import { Colors, ColorVariants } from "./types/theme";

export const colors: Record<Colors, ColorVariants | string | any> = {
  primary: {
    10: "#fbfbfa",
    15: "#F2F5F4",
    25: "#F9FBF2",
    30: "#edf4e2",
    50: "#E4F3C2",
    100: "#CFEE92",
    200: "#BBE762",
    300: "#A5D741",
    400: "#8EC721",
    500: "#78B703",
    600: "#5C9909",
    700: "#3F7A18",
    800: "#245B23",
    900: "#093C2F",
  },
  gray: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#EAFCF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  success: {
    25: "#F6FEF9",
    50: "#ECFDF3",
    100: "#D1FADF",
    200: "#A6F4C5",
    300: "#6CE9AC",
    400: "#32D583",
    500: "#12B76A",
    600: "#039855",
    700: "#027A48",
    800: "#05603A",
    900: "#054F31",
  },
  warning: {
    25: "#FFFCF5",
    50: "#FFFAEB",
    100: "#FEF0C7",
    200: "#FEDF89",
    300: "#FEC84B",
    400: "#FDB022",
    500: "#F79009",
    600: "#DC6803",
    700: "#B54708",
    800: "#93370D",
    900: "#7A2E0E",
  },
  error: {
    25: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    500: "#F04438",
    600: "#D92D20",
    700: "#B42318",
    800: "#912018",
    900: "#7A271A",
  },
  secondaryColor: "#F1F8E5",
  primaryColor: "#78B703",
  white: "#ffffff",
  transparent: "transparent",
  black: "#000000",
};

export const pieColors = [
  "#083C2F",
  "#78B700",
  "#BBE762",
  "#00828A",
  "#EE8D07",
  "#FC976A",
  "#FAC476",
  "#B4B590",
  "#C5BDB5",
  "#6F8EA1",
  "#374C59",
  "#815878",
  "#5C476F",
  "#241468",
  "#9F0D7F",
  "#EA1179",
  "#F79BD3",
];
