import styled from "styled-components";
import TopHeader from "src/components/TopHeader";
import BestSmartfolios from "./containers/BestSmartfolios";
import GoalForm from "./containers/GoalForm";
import { useSelector } from "react-redux";
import { RootStateType } from "src/store/rootReducer";
import ContributionForm from "./containers/ContributionForm";
import SolveInitialForm from "./containers/SolveInitialForm";
import SolveDurationForm from "./containers/SolveDurationForm";
import { theme } from "src/theme/theme";

export const PageWrapper = styled.div`
  border-radius: 12px;
  padding: 20px;
  background-color: white;
  width: 100%;
`;

export const StyledPredictionForm = styled.div`
  background-color: ${theme.colors.primary[10]};
  padding: 18px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  @media (max-width: 650px) {
    width: 100%;
  }
  gap: 18px;
`;

export const StyledFormSection = styled.div`
  padding: 16px;
  border-radius: 5px;
  background-color: ${(props) => props.color ?? "white"};
`;

const StyledBody = styled.div`
  display: flex;
  gap: 1.4em;
  align-items: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
  margin: auto;
  max-width: 3000px;
`;

const GoalBasedPredictions = () => {
  const menuState = useSelector((state: RootStateType) => state.menuReducer);
  return (
    <PageWrapper>
      <TopHeader />
      <StyledBody>

        {menuState.currentState === "GoalAmount" ? (
          <GoalForm />
        ) : menuState.currentState === "ContributionAmount" ? (
          <ContributionForm />
        ) : menuState.currentState === "SolveInitial" ? (
          <SolveInitialForm />
        ) : (
          <SolveDurationForm />
        )}
        <div style={{ width: "100%", flex: "1" }}>
          <BestSmartfolios />
        </div>
      </StyledBody>
    </PageWrapper>
  );
};

export default GoalBasedPredictions;
