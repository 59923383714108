import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateType } from "../rootReducer";

interface MarketState {
  group: string;
  market?: string;
  layers: Array<{ key: string; value: boolean }>;
  classes: Array<{ key: string; value: boolean }>;
  selectedSmartfolioId: number;
}

const initialState: MarketState = {
  group: "",
  market: null,
  selectedSmartfolioId: 0,
  layers: [],
  classes: [],
};

const marketSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    setSmartfolioId: (state, action: PayloadAction<number>) => {
      state.selectedSmartfolioId = action.payload;
    },
    setMarketState: (state, action: PayloadAction<string>) => {
      state.market = action.payload;
      state.classes = [];
      state.layers = [];
    },
    setGroupState: (state, action: PayloadAction<string>) => {
      state.group = action.payload;
    },
    setLayer: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>,
    ) => {
      const { key, value } = action.payload;
      const index = state.layers.findIndex((l) => l.key === key);
      if (index !== -1) {
        state.layers[index].value = value;
      } else {
        state.layers.push({ key, value });
      }
    },
    setClass: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>,
    ) => {
      const { key, value } = action.payload;
      const index = state.classes.findIndex((c) => c.key === key);
      if (index !== -1) {
        state.classes[index].value = value;
      } else {
        state.classes.push({ key, value });
      }
    },
    toggleLayer: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const layer = state.layers.find((l) => l.key === key);
      if (layer) {
        layer.value = !layer.value;
      } else {
        state.layers.push({ key, value: true });
      }
    },
    toggleClass: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const clazz = state.classes.find((c) => c.key === key);
      if (clazz) {
        clazz.value = !clazz.value;
      } else {
        state.classes.push({ key, value: true });
      }
    },
  },
});

export const selectMarket = (state: RootStateType) =>
  state.marketReducer.market;

export const selectGroup = (state: RootStateType) => state.marketReducer.group;

export const selectSelectedSmartfolioId = (state: RootStateType) =>
  state.marketReducer.selectedSmartfolioId;

export const selectLayers = (state: RootStateType) =>
  state.marketReducer.layers;

export const selectClasses = (state: RootStateType) =>
  state.marketReducer.classes;

export const selectActiveAssets = (state: RootStateType) =>
  state.marketReducer.classes
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectActiveLayers = (state: RootStateType) =>
  state.marketReducer.layers
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectAllActiveKeys = (state: RootStateType) => {
  const activeAssets = selectActiveAssets(state);
  const activeLayers = selectActiveLayers(state);
  return [...activeAssets, ...activeLayers];
};

export const {
  toggleClass,
  toggleLayer,
  setMarketState,
  setLayer,
  setClass,
  setSmartfolioId,
  setGroupState,
} = marketSlice.actions;
export default marketSlice.reducer;
