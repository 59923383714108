import { colors } from "src/theme/colors";
import styled from "styled-components";
import { Link } from "react-router-dom";
export const StyledItemLink = styled(Link)`
  border: none;
  background-color: ${({ isActive }) =>
    isActive ? colors.primary[50] : "white"};
  color: ${({ isActive }) => (isActive ? colors.primary[500] : "black")};;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: flex-start;
  width: 175px;
  border-radius: 5px;
  padding: 8px 16px 8px 16px;
  column-gap: 14px;
  
  & {
    svg {
      fill: ${({ isActive }) => (isActive ? colors.primary[500] : "black")};
      font-weight: bold;
    }
  }
  &:hover {
    background-color: ${colors.primary[50]};
    color: ${colors.primary[500]};
    cursor: pointer;
    svg {
      fill: ${colors.primary[500]};
      font-weight: bold;
    }
`;
export const StyledUsername = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border-top: 1px solid #e8f1ff;
  padding: 2rem;
  width: $({width} => width);
`;
export const StyledUsernameText = styled.span`
  font-size: 14px;
  color: #000000;
  font-weight: 400;
`;
