import React from "react";
import { theme } from "src/theme/theme";
import styled from "styled-components";

export interface SliderTitleProps {
  title: string;
  subtitle: string;
  green?: boolean;
  optional?: boolean;
}

const StyledTitles = styled.div`
  font-family: "Proxima Nova", sans-serif;
  line-height: 0.9em;
  margin: 0;
`;

const StyledTitle = styled.h2`
    font-weight: normal;
    color: ${props => props.green ? theme.colors.primary[500] : theme.colors.primary[900]};
  `;

const MainTitle = styled.span`
    font-size: 1.2rem;
    font-weight: bold;
    @media (max-width: 570px) {
      line-height: 1.2em;
    }
  `;

export const StyledSubtitle = styled.h3`
    font-size: .9rem;
    font-weight: 400;
    margin-bottom: 0;
    color: ${theme.colors.primary[400]};
  `;

export const SliderTitle: React.FC<SliderTitleProps> = ({
  title,
  subtitle,
  green,
  optional,
}) => {
  return (
    <StyledTitles>
      <StyledTitle green={green}>
        <MainTitle>{title}</MainTitle>
        {optional && <span style={{ marginLeft: "5px" }}>(Optional)</span>}
      </StyledTitle>
      <StyledSubtitle green={green}>{subtitle}</StyledSubtitle>
    </StyledTitles>
  );
};
