import { InputNumber } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { SliderTitle, SliderTitleProps } from "./SliderTitle";
import SolveDurationInput from "./SolveDurationInput";
import { theme } from "src/theme/theme";

export interface EstimatedAmountProps {
  amount: number | { months: number; years: number };
  className?: string;
  titles: SliderTitleProps;
}

export const StyledAmountInput = styled(InputNumber)`
  &.ant-input-number {
    border-radius: 5px;
    border: 1px solid #12b76a40;
    background: ${theme.colors.primary[25]};
  }
  position: relative;

  &::before {
    content: "$";
    position: absolute;
    left: 10px;
    top: 7px;
    pointer-events: none;
    color: #12b76a;
  }

  .ant-input-number-input {
    padding: 6px 0 0 21px;
    color: ${theme.colors.primary[500]};
  }
  width: 14em;
  height: 2.8em;
  @media (max-width: 570px) {
    width: 100%;
  }
`;

export const EstimatedAmountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 570px) {
    flex-direction: column;
    gap: 1em;
    align-items: start;
  }
`;

const EstimatedAmount: React.FC<EstimatedAmountProps> = ({
  titles,
  amount,
  className,
}) => {
  return (
    <div className={className}>
      <EstimatedAmountWrapper>
        <SliderTitle {...titles} green />
        {typeof (amount) === "number" && (
          <StyledAmountInput
            disabled
            value={amount}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          />
        )}
        {typeof (amount) === "object" && <SolveDurationInput years={amount.years} months={amount.months} />}
      </EstimatedAmountWrapper>
    </div>
  );
};

export default EstimatedAmount;
