import React from "react";
import { Emphasis } from "./style";

export const PublicMarketItems = [
  {
    // title: "SNB Public Market Smartfolios",
    Text: (
      <>
        <p>
          This Risk Disclosure Statement is provided to you in compliance with
          our requirements under FSRA in ADGM. It provides a general description
          of the nature and risks of investing in Smartfolios with SNB. SNB
          offers investing in Thematic Smartfolios that are linked to Underlying
          Securities/Products such as single stocks, ETFs, futures, options or
          other Underlying Securities/Products.
        </p>
      </>
    ),
  },
  {
    title: "YOU SHOULD UNDERSTAND THE RISKS BEFORE INVESTING",
    Text: (
      <>
        <p>
          You should not engage in investing in Smartolios with SNB unless you
          fully understand their nature and the extent of your exposure to risks
          and stop loss parameters. SNB offers Smartfolios on a range of
          Underlying Securities/Products (e.g. Single Stocks, ETFs) and each of
          the Underlying Securities/Products Types has risks that are specific
          to that type, for example with regard to the range and speed of price
          fluctuations and market liquidity. You should make sure you understand
          and accept all the specific risks of the Underlying
          Securities/Products Type before you invest in the related Smartfolios.
        </p>
      </>
    ),
  },
  {
    title: "RISK OF LOSS OF CAPITAL",
    Text: (
      <>
        <p>
          The value of financial instruments may fall as well as rise. When
          investing in financial instruments, there is a risk that you may lose
          some or all of your original/principal investments. You should
          consider whether investing in respective financial instruments is
          suitable for you in light of your individual circumstances and taking
          account your investment objectives, experience and financial position.
          The value of the Smartfolios may fluctuate rapidly and over wide
          ranges, and will be influenced by, among other things, the market
          price of the Underlying Securities/Products of the Smartfolio, the
          earnings and performance of the company or companies whose stocks are
          part of the Underlying Securities/Products or a related index, the
          performance of the economy as a whole, the changing supply and demand
          relationship for the Underlying Securities/Products or related
          instruments and indexes, governmental, commercial and trade programs
          and policies, interest rates, national and international political and
          economic events and the prevailing psychological characteristics of
          the relevant marketplace.
        </p>
      </>
    ),
  },
  {
    title: "LIQUIDITY RISK",
    Text: (
      <p>
        As far as markets are open and working efficiently, SNB shall provide
        daily liquidity on all the Smartfolios. Nonetheless, in case of lack of
        market data, halts or suspensions or errors or illiquidity or volatility
        in the market for the Underlying Securities/Products, SNB's own risk or
        profit parameters, technical errors, communication problems, market or
        political or economic or governmental events, acts of God or nature, or
        for other reasons, SNB will not be obligated and does not guarantee the
        availability of liquidity for any Smartfolio/Underlying
        Securities/Products. SNB may in its sole discretion cease offering
        Smartfolios and/or cease entering new Smartfolio transactions at any
        time.
      </p>
    ),
  },
  {
    title: "RISK OF DISRUPTION",
    Text: (
      <p>
        SNB relies on computer software, hardware and telecommunications
        infrastructure and networking to provide its services to clients, and
        without these systems SNB cannot provide the services. These
        computer-based systems and services such as those used by SNB are
        inherently vulnerable to disruption, delay or failure, which may cause
        you to lose access to the SNB platform or may cause SNB not to be able
        to provide Smartfolio investment or valuation, or may negatively affect
        any or all aspects of SNB's services. Under the SNB Agreement, you
        accept the SNB systems and services "As-Is" and our liability to you is
        limited.
      </p>
    ),
  },
  {
    title: "RISK OF FOREIGN CURRENCY FLUCTUATION",
    Text: (
      <p>
        Some Smartfolios may contain Underlying Securities/ Products that are
        denominated in a currency other than the base currency or currency you
        have on deposit in your SNB account. Thus, their profits or losses will
        be further affected by fluctuations in the exchange rates between the
        account currency and the currency in which the Underlying Securities/
        Products of the Smartfolio are denominated.
      </p>
    ),
  },
  {
    title: "RISK OF REGULATORY AND TAXATION CHANGES",
    Text: (
      <p>
        Changes in taxation and other laws, government, fiscal, monetary and
        regulatory policies may have an adverse effect on the value of your
        Smartfolios, the tax you pay on your Smartfolios, and the total return
        on your Smartfolios.
      </p>
    ),
  },
  {
    title: "NO TAX ADVICE",
    Text: (
      <p>
        SNB does not provide tax advice. You should obtain your own financial,
        legal, taxation and other professional advice as to whether Smartfolios
        are an appropriate investment for you.
      </p>
    ),
  },
  {
    title: "NO RIGHTS ON THE UNDERLYING SECURITIES",
    Text: (
      <p>
        Smartfolios may invest in single stocks but shall not confer you any
        right, voting right, in any Underlying Securities/ Products nor entitle
        or oblige you to acquire, receive, hold, vote, deliver, dispose of or
        participate directly in any corporate action of any Underlying
        Securities/ Products.
      </p>
    ),
  },
  {
    title: "TRADE ERRORS",
    Text: (
      <p>
        SNB can cancel, adjust or close out transactions on Smartfolios after
        confirmation to you to correct errors, including but not limited to
        transactions executed at a time and price at or near which trades in the
        market for the Underlying Securities/ Products were cancelled or
        adjusted by exchanges or market centers.
      </p>
    ),
  },
  {
    title: "BROKERAGE FEES AND SPREADS",
    Text: (
      <p>
        Your Smartfolio will be bearing Brokers Commissions and bid-ask spreads
        as per market standards. SNB charges its annual Asset Management Fees on
        top, and upfront.
      </p>
    ),
  },
  {
    title: "CORPORATE ACTIONS",
    Text: (
      <p>
        In the event of a Corporate Action affecting the Underlying Securities/
        Products on Stocks or ETFs (e.g., splits, spin-offs, rights offerings,
        mergers and acquisitions, etc.), SNB has the right to in its sole
        discretion to determine the appropriate adjustment or modification or
        action to take, if any, and when, with respect to the Smartfolio to
        preserve the economic equivalent of the rights and obligations of the
        parties. As an addition or alternative to the foregoing, SNB reserves
        the right in its sole discretion to close out your Smartfolio’s open
        position in the Underlying Securities/ Products prior to the Corporate
        Action.
      </p>
    ),
  },
];
