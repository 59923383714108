import Invest from "src/components/Invest";
import { FC, useEffect } from "react";
import { Button, Col, Row, Typography } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";
import { CurrencySymbolLookup } from "src/utils/constants/currency";
import { Wrapper } from "./style";
import { useBuyTickerOrder } from "../../api/useBuyTickerOrder";
import { useSellStockOrder } from "../../api/useSellTickerOrder";
import { sideOrder } from "../../utils/constants";
import { fixNumber } from "src/utils/number";
import { useQueryClient } from "react-query";
import { ButtonTheme } from "src/components/Button/ButtonTheme";

interface SummaryModalProps {
  setSummaryModalVisible: any;
  isSummaryModalVisible: boolean;
  onCancel: () => void;
  order: any;
}

const { Paragraph, Title } = Typography;

export const SummaryModal: FC<SummaryModalProps> = ({
  setSummaryModalVisible,
  isSummaryModalVisible,
  order,
  onCancel,
}) => {
  const data = { ...order.formData, ...order.otherData };

  const queryClient = useQueryClient();

  const {
    mutate: sendBuyOrder,
    isLoading: isBuyOrderLoading,
    isSuccess: isBuyOrderSuccess,
  } = useBuyTickerOrder();

  const {
    mutate: sendSellOrder,
    isLoading: isSellOrderLoading,
    isSuccess: isSellOrderSuccess,
  } = useSellStockOrder();

  useEffect(() => {
    if (isBuyOrderSuccess === true || isSellOrderSuccess) {
      queryClient.invalidateQueries({ queryKey: ["brokerageOrders"] });
      setSummaryModalVisible(false);
      onCancel();
    }
  }, [isBuyOrderSuccess, isSellOrderLoading]);

  const handleConfirmOrder = () => {
    if (data.order_side === sideOrder.BUY) sendBuyOrder(order.formData);
    else sendSellOrder(order.formData);
  };

  const currencySymbol = CurrencySymbolLookup[data.currency];

  const rows = [
    {
      key: "order_side",
      label: "Side",
      prefix: "",
      suffix: "",
      color: "#00C92F",
    },
    { key: "order_type", label: "Type", prefix: "", suffix: "", color: "" },
    {
      key: "order_quantity",
      label: "Quantity",
      prefix: "",
      suffix: "",
      color: "",
    },
    {
      key: "order_price",
      label: "Price",
      prefix: currencySymbol,
      suffix: "",
      color: "",
    },
    {
      key: "order_value",
      label: "Value",
      prefix: currencySymbol,
      suffix: "",
      color: "",
    },
    {
      key: "order_limit_price",
      label: "Limit Price",
      prefix: currencySymbol,
      suffix: "",
      color: "",
    },
    { key: "tif", label: "TIF", prefix: "", suffix: "", color: "" },
    // { key: 'order_stop_price', label: 'Stop Price', prefix: currencySymbol, suffix: "", color: "" },
    // { key: 'duration', label: 'Order Duration', prefix: "", suffix: "", color: "" },
    // { key: 'take_profit', label: 'Take Profit', prefix: "+", suffix: "%", color: "" },
    // { key: 'stop_loss', label: 'Stop Loss', prefix: "-", suffix: "%", color: "" },
  ];

  const FIRST_SUBTITLE = "SNB will add the corresponding Management Fees.";
  const SECONDE_SUBTITLE = "We will send you a copy of this summary via email";

  return (
    <Invest.Modal
      title="Order Summary"
      closable={false}
      handleCancel={() => setSummaryModalVisible(false)}
      isModalVisible={isSummaryModalVisible}
      handleOk={() => console.log()}
      footer={null}
    >
      <div style={{ margin: "0px 4rem" }}>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Paragraph style={{ color: "#00000080" }}>
              {FIRST_SUBTITLE}
              <br />
              {SECONDE_SUBTITLE}
            </Paragraph>
          </Col>
        </Row>
        <Wrapper>
          <Title
            style={{ textAlign: "center" }}
            level={4}
          >{`${data["ticker_symbol"]} - ${data["ticker_name"]}`}</Title>
          {rows.map(
            (row) =>
              data[row.key] !== undefined && (
                <Row gutter={[16, 16]} key={row.key}>
                  <Col span={12} style={{ color: "#00000080" }}>
                    {row.label}
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: "right",
                      fontWeight: "600",
                      color: row.color,
                    }}
                  >
                    {row.prefix}
                    {typeof data[row.key] === "string"
                      ? data[row.key].replace(
                          /^./,
                          data[row.key][0].toUpperCase(),
                        )
                      : typeof data[row.key] === "number"
                      ? fixNumber(data[row.key], 3)
                      : data[row.key]}
                    {row.suffix}
                  </Col>
                </Row>
              ),
          )}
        </Wrapper>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ paddingTop: 18 }}>
            <ButtonTheme
              size="large"
              style={{ width: "100%", fontWeight: 600 }}
              onClick={() => setSummaryModalVisible(false)}
            >
              Cancel
            </ButtonTheme>
          </Col>
          <Col span={12} style={{ paddingTop: 18 }}>
            <ButtonTheme
              primary={true}
              size="large"
              style={{
                width: "100%",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleConfirmOrder}
              loading={
                data.order_side === sideOrder.SELL
                  ? isSellOrderLoading
                  : isBuyOrderLoading
              }
            >
              Confirm
              <RightCircleOutlined />
            </ButtonTheme>
          </Col>
        </Row>
      </div>
    </Invest.Modal>
  );
};
