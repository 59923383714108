import React from "react";
import { Area } from "@ant-design/charts";
import { StyledFormSection } from "../predictions";
import EstimatedAmount from "./EstimatedAmount";
import styled from "styled-components";
import { GoalStats } from "src/store/Goal-Based/goal";
import { SliderTitleProps } from "./SliderTitle";
import moment from "moment";
import { theme } from "src/theme/theme";

const StyledChart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
const customFormatter = Intl.NumberFormat("en", { notation: "compact" })
  .format as any;

export function AmountPredictionChart(props: {
  data: GoalStats | undefined;
  isLoading: boolean;
  titles: SliderTitleProps;
}) {
  const { data, isLoading, titles } = props;
  const transformedData = data?.flatMap((item) => [
    {
      index: item.index,
      estimationType: "optimistic",
      value: item["Optimistic scenario"],
    },
    {
      index: item.index,
      estimationType: "targeted",
      value: item["investment"],
    },
    {
      index: item.index,
      estimationType: "pessimistic",
      value: item["Pessimistic scenario"],
    },
  ]);

  const config = {
    data: transformedData ?? [],
    height: 170,
    xField: "index",
    yField: "value",
    yAxis: {
      label: {
        autoHide: true,
        formatter: customFormatter,
      },
    },
    tooltip: {
      formatter: (item) => {
        return { name: item.estimationType, value: Math.round(item.value) };
      },
    },
    seriesField: "estimationType",
    color: ["#4CAF50", "#8BC34A", "#CDDC39"],
    areaStyle: {
      fillOpacity: 0.6,
    },
    loading: isLoading,
  };

  function getAmount(title: string) {
    if (!data || data?.length === 0) return 0;

    if (title === "Goal Contribution")
      return data ? Math.round(data[0]["Contribution cumulative"]) : 0;
    if (title === "Final / Goal Amount")
      return data ? Math.round(data[data.length - 1]["investment"]) : 0;
    if (title === "Investment Initial Amount")
      return data
        ? Math.round(
            data[0]["Investment contribution"] -
              data[1]["Investment contribution"],
          )
        : 0;
    else {
      let duration = moment.duration(null);
      if (data) {
        const endDate = moment(data[data?.length - 1]["date"]);
        const startDate = moment(data[0]["date"]);
        duration = moment.duration(endDate.diff(startDate));
      }
      return data
        ? { years: duration.years(), months: duration.months() }
        : { years: 0, months: 0 };
    }
  }
  return (
    <StyledChart>
      <StyledFormSection color={theme.colors.primary[30]}>
        <EstimatedAmount amount={getAmount(titles.title)} titles={titles} />
      </StyledFormSection>
      <StyledFormSection color={theme.colors.primary[30]}>
        <Area {...config} />
      </StyledFormSection>
    </StyledChart>
  );
}
export default AmountPredictionChart;
