import React from "react";
import { Emphasis } from "./style";

export const CryptoItems = (clientType) => {
  return [
    {
      title: `SNB Cryptofolios Smartfolios`,
      Text: (
        <>
          {clientType === "Professional" ? (
            <p>
              This marketing material is intended only for
              Professional/Sophisticated Clients (as defined by the FSRA Conduct
              of Business module). No other person should act upon it.
            </p>
          ) : (
            ""
          )}

          <p>
            Past or projected performance is not necessarily a reliable
            indicator of future results.
          </p>
        </>
      ),
    },
    {
      title: "Value Fluctuation Risks",
      Text: (
        <>
          <p>
            Cryptocurrency Smartfolios involve a{" "}
            <Emphasis>high risk of capital loss</Emphasis> and should be
            considered a{" "}
            <Emphasis>Highly Risky and Highly Speculative investment</Emphasis>{" "}
            and are not recommended to all. Cryptocurrency prices can be highly
            influenced and subject to extreme fluctuations and will directly
            affect the value of the smartfolios: they can gain value or become
            worthless over time. This is similar to other goods or assets, but
            with a much higher level of volatility and risk.
          </p>
        </>
      ),
    },
    {
      title: "Liquidity and Counterparty Risks",
      Text: (
        <>
          <p>
            SNB Cryptocurrency Smartfolios have only daily liquidity, 5 times a
            week, except on bank holidays, even though the underlying
            cryptocurrencies might be trading 24/7.{" "}
            <Emphasis>
              In times of high volatility and large market swings, “market
              disruption” warnings along with trading halts could be issued,
              market counterparties may not be able to honor the day’s buy and
              sell orders
            </Emphasis>
            : crypto-linked products, even those that trade on regulated
            exchanges, may face liquidity risks from the large swings in the
            market.{" "}
            <Emphasis>
              Stop Loss and Take Profit triggers are not guaranteed to be
              executed
            </Emphasis>{" "}
            as they would in normal market conditions, and your Stop Loss or
            Profit Taking could be met with a significantly disadvantageous
            impact.
          </p>
          <p>
            You should accordingly calibrate the size of your cryptocurrency
            investments relative to your personal wealth and public market
            investment portfolio and{" "}
            <Emphasis>
              be prepared to lose most, or all, of the amount invested in
              cryptocurrency smartfolios
            </Emphasis>
            .
            <Emphasis>
              You acknowledge that SNB will not assume or hold any
              responsibility
            </Emphasis>
            .
          </p>
        </>
      ),
    },
    {
      title: "No Investment Advice",
      Text: (
        <>
          The information provided on www.finamaze.com does not constitute
          investment advice, financial advice, trading advice, nor any other
          sort of advice, and you should not treat any of the website content as
          such.{" "}
          <Emphasis>
            SNB does not recommend that any cryptocurrency should be bought,
            sold, or held by you
          </Emphasis>
          . Do conduct your own tax, legal and financial due diligence before
          making any investment decisions.
        </>
      ),
    },
    {
      title: "Accuracy of Information",
      Text: (
        <>
          SNB will strive to ensure the accuracy and completeness of the
          information listed on this website, although it will not accept or
          hold any responsibility for any missing information, incorrect
          information obtained from third parties, or outdated information. SNB
          provides all information as is.{" "}
          <Emphasis>
            You understand that you are using any and all information available
            herein at your own risk and shall obtain independent advice or
            opinion
          </Emphasis>
          .
        </>
      ),
    },
  ];
};
