export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const DASHBOARD = "/dashboard";
export const INVEST = "/invest/:id";
export const INVEST_PUBLIC = "/invest/public-market";
export const INVEST_PRIVATE = "/invest/private-market";
export const BROKERAGE = "/brokerage";
export const BROKERAGE_DETAILS = "/brokerage/stock";
export const BROKERAGE_ORDERS = "/brokerage/orders";
export const DEPOSIT = "/deposit";
export const DEPOSITLEAN = "/lean-deposit";
export const WITHDRAW = "/withdraw";
export const SETTINGS = "/settings";
export const OURSMARTFOLIOS = "/smartfolios";
export const INVESTMENT_DETAILS = "/investment";
export const ERROR = "/error";
export const MARKETS = "/markets";
export const GOAL_BASED = "/goal-based-investment";
export const GOAL_PREDICTIONS = "/goal-predictions";
export const GOAL_BASED_SMARTFOLIO = "/goal-predictions/:planId";
export const SINGLESMARTFOLIO = "/smartfolios/:id";

export const NOTIFICATIONS = "/notifications";
export const COMPLAINT = "/Complaint";
export const RISK_ASSESSMENT_OBJECTIVES = "/risk-assessment-objectives";
export const ONBOARDING = "/onboarding";
export const EMAILVERIFY = "/onboarding/verify/:code?";
export const REGISTER = "/register";
export const REMINDER_UNSUB = "/reminder/unsub/:id/:token";
export const REPORT_UNSUB = "/report/unsub/:id/:token";

export const NEWOURSMARTFOLIOS = "/newsmartfolios"

export const NEW_INVEST_PUBLIC = "/invest/public/:id"
export const NEW_INVEST_PRIVATE = "/invest/private/:id"
